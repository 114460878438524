.footer{
	color: $secondary-color-fade;
	@media( max-width: 552px){ font-size: 0.95em; }

	.fb{
		&:before{
			content: " ";
		    display: inline-block;
		    width: 17px;
		    height: 17px;
		    background: url(../images/facebook.png);
		    background-size: 100%;
		    margin-bottom: -2px;
		    margin-right: 5px;
		}
	}
	.footer__primary{
		background: $secondary-color; 
		padding: 25px 0;
		a{
			color: $white-color;
			border-bottom: 1px solid $secondary-color-border;
		}
	}
	.footer__secondary{
		background: $secondary-color-darker;
		padding: 25px 0;	
		a{
			color: $white-color;
		}
		
		.footer-nav{
			padding-left: 0;
			margin-bottom: 0;
			@media( min-width: 992px){ float: left; }
			li{
				display: inline-block;
				margin-right: 5px;
			}
		}
		.footer-copy{
			@media( min-width: 992px){ float: right; }
		}
	}
}

