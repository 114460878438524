.slider{
	margin-top: -40px;
	margin-bottom: 25px;
	@media (max-width: 992px) {
		padding:0;
	}
	padding-left: 0;
	@media (min-width: 992px) {
		&:after{
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-left: 20px solid $secondary-color;
			border-bottom: 70px solid transparent;
			position: absolute;
			right: 5px;
			top: 0px;
		}
	}
}
#my-slider{
	.sp-buttons{
		
		@media (min-width: 992px) {
			position: absolute;
		    left: 15px;
		    width: auto;
		    bottom: 8px;
		    margin-bottom: 0;
		}
		.sp-button{
	    	border-color: $primary-color;
	    	background: $primary-color;
	    	&.sp-selected-button{
	    		background: $white-color;
	    	}
	    }
	}
	.slider-text{
		background: $secondary-color;
		color: $secondary-color-fade;
		max-width: 80%;
		p{
			padding: 10px 15px;
			margin-bottom: 0;
			&:before{
				content: '';
				display: block;
				width: 0;
				height: 0;
				border-right: 25px solid $secondary-color;
				border-top: 107px solid transparent;
				position: absolute;
				left: -25px;
				top: 0;
			}
		}
		a{
			color: $white-color;
			border-bottom: 1px solid $secondary-color-border;
		}
	}
}
.intro{
	margin-bottom: 25px;
}

.beren{
	padding: 25px 0;
	background: $primary-color;
	color: $white-color;
	margin-top: 40px;
	h3{
		color: $white-color;
		margin-bottom: 25px;
		a{
			color: $white-color;
			border-bottom: 1px solid $primary-color-border;
			&:hover{
				color: $white-color;
			}
		}
	}

	h5{
		font-size: 16px;
		line-height: 20px;
	}
	.eindbeer{
		h5{
			padding: 0 15px;
			margin-top: 10px;
		}
	}
}

.land{
	margin-top: 15px;
	.column{
		width: 100%;
		text-align: center;
	}
}
.vestigingen{
	padding-top: 45px;
}

.side{
	background: #e3e3e3;
	padding: 25px 0;
}

.intro{
	margin-bottom: 25px;
}

.floated-left{
	@media (min-width: 768px) {padding-right: 0px;}
	margin-bottom: 15px;
}

.floated-right{
	@media (min-width: 768px) {padding-left: 0px;}
	margin-bottom: 15px;
}

.list-item{
	margin: 0;
	.col-xs-12{
		@media (max-width: 544px) { padding: 0; }
		@media (min-width: 544px) { padding-right: 0; }
	}
}

@media (max-width: 768px) {
	.floated-right-his{
		padding-left: 0px;
		margin-bottom: 15px;
	}
}

.newsitem{
	margin-bottom: 30px;
}
.newsblock{
	header{
		margin-bottom: 15px;
	}
}

