.header {
  width: 100%;
  color: $white-color;
  .navbar {
    width: 100%;
    padding: 0;
  }
  .navbar-collapse {
    padding: 20px;
    padding-top: 10px;
    background: $brand-primary;
  }
  .nav-header {
    position: relative;
    @media (max-width: 768px) {
      background: $secondary-color;
    }
    @media (min-width: 768px) {
      padding-top: 92px;
    }
    @media (min-width: 992px) {
      padding-top: 56px;
    }
  }
  .navbar-toggle {
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 40px;
    border: transparent;
    padding: 10px;
    border-radius: 0px;
    .icon-bar {
      display: block;
      width: 22px;
      background-color: $white-color;
      height: 3px;
      margin-bottom: 4px;
      border-radius: 2px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .brand {
    background-color: #fff;
    z-index: 5;
    padding: 20px;
    padding-bottom: 15px;
    display: block;
    margin-right: 75px;
    position: relative;
    width: 280px;
    &:after{
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-left: 25px solid #fff;
      border-bottom: 107px solid transparent;
      position: absolute;
      right: -25px;
      top: 0;
    }

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      margin: 0;
      padding: 10px 20px;
      &:after{
        border-bottom: 92px solid transparent;
      }
    }
    @media (min-width: 992px) {
      padding: 30px;
      width: 310px;
      &:after{
        border-left: 35px solid #fff;
        border-bottom: 140px solid transparent;
        right: -35px;
      }
    }
    @media (min-width: 1100px) {
      width: auto;
      padding-left: 50px;
    }
    @media (min-width: 1200px) {
      &:after{
        border-left: 45px solid #fff;
        border-bottom: 170px solid transparent;
        right: -45px;
      }
    }
    @media (min-width: 1650px) {
      padding-left: 155px;
    }
    .logo{
      background: #fff url($logo) no-repeat;
      height: 72px;
      width: 225px;
      display: block;
      background-size: 100%;
      @media (min-width: 992px) {
        height: 80px;
        width: 250px;
      }
      @media (min-width: 1200px) {
        height: 110px;
        width: 350px;
      }
    }
    em{
      position: absolute;
      left: -9999999px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

#main-nav,
#upper-nav {
  padding: 15px 25px;
  a {
    font-family: $brand-font-family;
    text-decoration: none;
    color: $white-color;
  }
}

#main-nav {
  background: $primary-color; 
  margin: 0;
  @media (min-width: 768px) {
     text-align: center;
     padding: 15px;
  }
  @media (min-width: 992px) {
    text-align: right;
    padding: 15px 25px;
  }
  @media (min-width: 1400px) {
    padding: 27px;
  }
  li {
    border-right: 1px solid $primary-color-border;
    padding: 0 9px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    
    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (min-width: 1650px) {
       font-size: 17px;
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
    }
    a {
      height: 50px;
      color: $white-color;
      display: block;
      @media (min-width: 768px) {
        padding: 0;
        &:hover {
          color: $primary-color-fade;
        }
      }
      @media (min-width: 768px) and (max-width: 992px){
        height: auto;
        br{
          content: ' ';
          &:after{
            content: ' ';
          }
        }
      }
      @media (min-width: 1400px) {
        height: auto;
        br{
          content: ' ';
          &:after{
            content: ' ';
          }
        }
      }
    }
    &.active {
      a {
        color: $primary-color-fade;
      }
    }
  }
}

#upper-nav {
  background: $secondary-color;
  text-align: right;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0;
  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 16px;
  }
  @media (min-width: 992px) {
    padding: 15px 25px;
  }
  li {
    border-right: 1px solid $secondary-color-border;
    @media (min-width: 768px) {
      display: inline-block;
      padding: 0 15px;

      &:last-child {
        padding-right: 0;
        border-right: none;
      }
    }
    &.active {
      a {
        color: $secondary-color-fade;
      }
    }
    a {
      font-size: 14px;
      color: $white-color;
    }
  }
}



/** MOBILE **/

#menu-main,
#menu-upper {
  a {
    color: $white-color;
    text-decoration: none;
    padding: 5px 0;
    display: block;
    br{
      content: ' ';
      &:after{
        content: ' ';
      }
    }
    &:hover {
      text-decoration: none;
    }
  }

  li {
    border-bottom: 1px solid $primary-color-border;
    &.active {
      a {
        color: $primary-color-fade;
      }
    }
  }
}

