// Grid settings
$enable-flex:           false;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$grid-gutter-width: 50px;

$logo: '../images/ki-clincke-topgen-ki.svg';

// Colors
$white-color: 			#fff;
$black-color: 			#000;
$primary-color: 		#3c78b7;
$primary-color-fade: 	#9ebcdb;
$primary-color-border: 	#6897c7;
$primary-color-darker: 	#386ea8;

$secondary-color: 		#00aab2;
$secondary-color-fade: 	#b4fbfe;
$secondary-color-border:	#4dc4c9;
$secondary-color-darker:#0098a1;

$base-color: 			#707173;

$link-color: 			$primary-color;
$brand-primary: 		$primary-color;

// Fonts
$base-font-family: 'Open Sans', sans-serif;
$brand-font-family: 'Armata', $base-font-family;