body {
  font-family: $base-font-family;
  color: $base-color;
  font-size: 15px;
  line-height: 25px;
}

img {
  max-width: 100%;
  height: auto;
}
.img{
	max-width: 100%;
	display: inline-block;
	position: relative;
	 &:after{
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-left: 20px solid $secondary-color;
		border-bottom: 70px solid transparent;
		position: absolute;
		right: -20px;
		top: 0px;
	}
}


a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}


.content{
	padding: 40px 0;
	a {
	  border-bottom: 1px solid rgba($brand-primary, 0.5);
	  color: $brand-primary;
	  text-decoration: none;

	  &:hover,
	  &:focus {
	    color: $brand-primary;
	    text-decoration: none;
	    border-bottom-color: rgba($brand-primary, 0.8);
	  }
	}
}
.page-template-template-bestellen{
	.content{
		padding-bottom: 0;
	}
}

h1{
	font-family: $brand-font-family;
	color: $brand-primary;
	font-size: 2em;
	margin-bottom: 0.7em;
}

h2{
	font-family: $brand-font-family;
	color: $brand-primary;
	font-size: 1.7em;
	margin-bottom: 0.7em;
}

h3{
	font-family: $brand-font-family;
	color: $brand-primary;
	font-size: 1.3em;
}



@media (min-width: 544px) and (max-width: 1200px) {
	.container {
	    max-width: 100%;
	}
}

@media (min-width: 1200px) {
	
}


figure{
	p{
		margin-bottom: 5px;
	}
}