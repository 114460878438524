.content{
	.btn{
		color: $white-color;
		border-radius: 40px;
		&:hover{
			color: $white-color;
		}
	}
}

.btn-primary{
	border: 1px solid $primary-color-darker;
}
.btn-secondary{
	color: $white-color;
	background: $secondary-color;
	border: 1px solid $secondary-color-darker;
	&:hover{
		background-color: $secondary-color-darker;
		color: $white-color;
	}
}
.tel{
	padding-left: 50px;
	padding-right: 20px;
	background-image: url('../images/tel.png');
	background-repeat: no-repeat;
	background-position: 15px center;
	background-size: 25px;
	font-size: 1em;
	margin-bottom: 15px;
	margin: 5px;
	strong{
		font-size: 1.5em;
	}
}